import React, { useState, useRef, useEffect } from 'react'
import { Editor, Realtime } from '@minutes/components'
import { useEditor } from 'components/contexts/editor'
import { ClassNames } from '@emotion/react'
import i18n from 'i18next'
import { useSavingContext } from 'components/contexts/saving'
import { Editor as EditorType } from 'components/contexts/editor'
import { ToolbarOptions } from '../../../assets/ckeditor/toolbars'
import selectors from '../../../selectors/minuteTakerSelectors'
import { pathOr } from 'rambdax'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { DOCUMENT_STATUS } from './InvitationView/types'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'

type Props = {
    onChange?: (editor: EditorType) => void
    data?: string
    editorConfig?: Record<string, any>
    onEditorInit?: (editor: any) => void
    realtime?: boolean
    readonly?: boolean
    handleError?: (error: any) => void
    currentMinuteItem: any
    getAiSupport?: () => void
}

function MinutesEditor({
    onChange,
    data,
    editorConfig,
    onEditorInit,
    realtime,
    readonly,
    handleError,
    currentMinuteItem,
    getAiSupport
}: Props) {
    const editor = useEditor()
    const { setIsSaving } = useSavingContext()
    const [initData] = useState(data)
    const editorRef = useRef<any>(null)
    const [hasFocus, setHasFocus] = useState(false)
    const ref = useRef<HTMLDivElement>(null)
    const platform = getSessionStorageItem('parentPlatform')
    /**
     * Set saving context if CK Editor is pending a save
     * Note: this is only applicable to review editor since
     * thats the only editor using autosave
     */

    useEffect(() => {
        if (
            hasFocus &&
            !!editor.currentEditor &&
            editor.currentEditor.plugins.get('PendingActions')
        ) {
            const pendingActions = editor.currentEditor.plugins.get('PendingActions')
            pendingActions.on('change:hasAny', (_, __, newValue) => {
                if (newValue) {
                    setIsSaving(true)
                } else {
                    setIsSaving(false)
                }
            })
        }
    }, [editor, hasFocus, setIsSaving])

    useEffect(() => {
        if (editorRef.current) {
            if (readonly) {
                editorRef.current.enableReadOnlyMode('globalEditorLock')
            } else {
                editorRef.current.disableReadOnlyMode('globalEditorLock')
            }
        }
    }, [readonly])

    function handleFocus(editorInstance) {
        editor.setCurrentEditor(editorInstance)
        setHasFocus(true)
    }

    function handleBlur() {
        // editor.setCurrentEditor(undefined)
        setHasFocus(false)
    }

    function handleChange() {
        if (onChange && editor) {
            // Disable save on init by checking if data and editor data are different
            const currentEditor = editor.currentEditor
            !!currentEditor && onChange(currentEditor)
        }
    }

    function handleOnInit(editor) {
        editorRef.current = editor
        if (ref.current) {
            ref.current?.appendChild(editor.ui.view.toolbar.element)
            ref.current?.appendChild(editor.ui.getEditableElement())
            !!onEditorInit && onEditorInit(editor)
        }
        //editor.isReadOnly = readonly
        readonly
            ? editor.enableReadOnlyMode('globalEditorLock')
            : editor.disableReadOnlyMode('globalEditorLock')
    }

    /*function handleToolbarClick() {
        if (editorRef && editorRef.current != null) {
            editorRef!.current!.editing!.view.focus()
        }
    }*/
    /**
     * Enable the list option to active the number,roman and alphabet option for list item.
     */
    const bookSummarizationEnabled = transientStorageManager.book_summarization_enabled
    const AiTranslations = {
        AI_ERROR_CONTENT: i18n.t('AI_ERROR_CONTENT'),
        AI_GEN_RESULT: i18n.t('AI_GEN_RESULT'),
        AI_INSERT: i18n.t('AI_INSERT'),
        AI_HEADER_TITLE: i18n.t('AI_HEADER_TITLE'),
        AI_INACCURACIES: i18n.t('AI_INACCURACIES'),
        AI_ERROR_MODAL_HEADER: i18n.t('AI-ERROR-MODAL-HEADER'),
        AI_INSERT_TOAST_MESSAGE: i18n.t('AI_INSERT_TOAST_MESSAGE'),
        AI_NO_ACCESS_ERROR_MESSAGE: i18n.t('AI_NO_ACCESS_ERROR_MESSAGE'),
        COPY: i18n.t('COPY'),
        CLOSE: i18n.t('CLOSE'),
        TOAST_DISMISS_BUTTON: i18n.t('TOAST_DISMISS_BUTTON'),
        AI_INSUFFICIENT_TEXT_ERROR: i18n.t('AI_INSUFFICIENT_TEXT_ERROR'),
        AI_COPY_CLIP: i18n.t('AI_COPY_CLIP'),
        DRAG: i18n.t('DRAG')
    }
    const config = {
        heading: ToolbarOptions.heading,
        highlight: ToolbarOptions.highlight,
        //list: ToolbarOptions.list,
        typing: ToolbarOptions.typing,
        bookSummarizationEnabled,
        AiTranslations,
        ...editorConfig
    }
    const documentStatus = pathOr(
        '',
        ['attributes', 'status'],
        currentMinuteItem
    ) as DocumentStatusKey

    return (
        <ClassNames>
            {({ css }) => (
                <>
                    <div
                        className={css`
                            .ck.ck-toolbar {
                                z-index: 95;
                                border-left: 0;
                                border-right: 0;
                                top: ${documentStatus === DOCUMENT_STATUS.DRAFT ? '80px' : '0'};
                                width: 100%;
                            }
                        `}
                        // onClick={handleToolbarClick}
                        ref={ref}
                    />

                    <div
                        className={css`
                            padding: 0 40px;
                            .ck-focused {
                                border: 1px solid transparent !important;
                                box-shadow: none !important;
                            }

                            /* Ensure that long unbroken stings will wrap it all browsers */
                            > .ck-content {
                                overflow-wrap: break-word;
                            }
                        `}>
                        <ErrorBoundary>
                            {realtime ? (
                                <Realtime
                                    placeholder={i18n.t('EDITOR_PLACEHOLDER_TEXT')}
                                    onReady={handleOnInit}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    // onChange={handleChange}
                                    data={initData}
                                    runtimeConfig={config}
                                    handleError={handleError}
                                    getAiSupport={
                                        documentStatus === DOCUMENT_STATUS.DRAFT &&
                                        (platform === 'boardswebadmin' ||
                                            platform === 'boardswebdirector')
                                            ? getAiSupport
                                            : undefined
                                    }
                                />
                            ) : (
                                <Editor
                                    placeholder={i18n.t('EDITOR_PLACEHOLDER_TEXT')}
                                    onReady={handleOnInit}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    data={initData}
                                    runtimeConfig={config}
                                    getAiSupport={
                                        documentStatus === DOCUMENT_STATUS.DRAFT &&
                                        (platform === 'boardswebadmin' ||
                                            platform === 'boardswebdirector')
                                            ? getAiSupport
                                            : undefined
                                    }
                                />
                            )}
                        </ErrorBoundary>
                    </div>
                </>
            )}
        </ClassNames>
    )
}

class ErrorBoundary extends React.Component {
    state = { hasError: false }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.error(error, info)
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>
        }

        return this.props.children
    }
}

const mapStateToProps = (state, _) => {
    return {
        currentMinuteItem: selectors.currentMinuteItem(state.minuteTakerReducer)
    }
}

export default withRouter(connect(mapStateToProps)(MinutesEditor))
