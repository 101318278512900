import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import MinuteTakerHeader from '../MinuteTakerHeader'
import i18n from 'common/util/internationalization'
import { Box, Paper, makeStyles, Grid, Menu, MenuItem, Checkbox, Fade } from '@material-ui/core'
import {
    AtlasGreyLight,
    AtlasStatusAlert,
    ColorBaseMulberryBlue,
    ColorBaseRichBlack,
    ColorBaseWhite,
    ColorEastBay,
    FontWeightLightBold,
    FontWeightRegular
} from 'assets/styles/variables'
import { Button } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import OpenEyeIcon from 'assets/icons/OpenEyeIcon'
import CloseEyeIcon from 'assets/icons/CloseEyeIcon'
import ManageColumnIcon from 'assets/icons/ManageColumnIcon'
import { theme } from 'theme'
import ActionGridFilter from './ActionGridFilter'
import { CustomSortIconsObject } from 'assets/icons/CustomSortIcon'
import {
    OverlayNoRowsTemplate,
    getActionColumnSchemaForAdmin,
    getActionColumnSchemaForDirector
} from './ColumnComponents'
import { ColDef } from 'ag-grid-community'
import AgGridComponent from 'components/shared/table/AgGrid'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import actionEditorSelectors from 'selectors/actionEditorSelectors'
import { actions } from 'reducers/actionDashboardReducer'
import { exportXlsList, getFilteredActions } from 'businesslayer/networkrequests'
import { getCommitteeForWhichUserHasAction } from 'businesslayer/networkrequests/committees'
import LinearIndeterminate from 'components/shared/LinearProgress'
import { GRID_UTILS } from './Utils/Utils'
import { useMinutesManager } from 'pages/minutes-manager-page/use-minutes-manager'
import { saveFile } from 'common/util/file'
import ExportToExcelIcon from 'assets/icons/ExportToExcelIcon'
import { AddActionEditActionButton } from 'components/shared/StyledComponents'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import RightDrawer from './RightDrawer'
import { Details } from 'Types'
import NoActionIcon from 'assets/icons/NoActionIcon'
import WarningAlertIcon from 'assets/icons/WarningAlertIcon'
import {
    getSessionStorageItem,
    setSessionStorageItem
} from '../../../businesslayer/minutesLocalStore'

const useStyles = makeStyles(() => ({
    headerFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    toast: {
        position: 'fixed',
        gap: '16px',
        borderRadius: '2px',
        top: '5%',
        right: '2%',
        background: ColorBaseWhite,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
        borderLeft: `6px solid ${AtlasStatusAlert}`,
        padding: '4px 8px 4px 16px',
        height: '88px',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        minWidth: '334px',
        maxWidth: '334px'
    },
    toastMessage: {
        width: '185px',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: FontWeightRegular,
        lineHeight: '20px',
        color: ColorBaseRichBlack
    },
    root: {
        flexGrow: 1,
        maxWidth: '100vw'
    },
    headerTitleFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '16px',
        backgroundColor: ColorBaseWhite,
        gap: '16px'
    },
    savedText: {
        fontFamily: 'Source Sans Pro',
        fontSize: '12px',
        fontWeight: FontWeightRegular,
        lineHeight: '14px',
        textAlign: 'left',
        height: '24px',
        padding: '5px 10px',
        marginTop: '4px',
        gap: '10px',
        textWrap: 'nowrap',
        borderRadius: '12px',
        border: `1px solid ${AtlasGreyLight}`,
        background: AtlasGreyLight
    },
    buttonSectionCss: {
        marginLeft: '1rem',
        display: 'flex',
        alignItems: 'flex-start'
    },
    titleCss: {
        maxWidth: '350px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    backButton: {
        backgroundColor: ColorBaseWhite,
        border: 'none',
        cursor: 'pointer',
        outline: 'none',
        marginRight: '8px',
        width: '40px',
        height: '40px',
        '&:focus, &:active': {
            outline: 'none',
            width: '40px',
            height: '40px',
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        }
    },
    minutesHeaderTitle: {
        gap: '16px',
        fontFamily: 'Source Sans Pro',
        fontSize: '28px',
        fontWeight: FontWeightLightBold,
        lineHeight: '32px',
        color: '#282E37'
    },
    minutesHeaderDate: {
        fontFamily: 'Source Sans Pro',
        fontSize: '18px',
        fontWeight: FontWeightRegular,
        lineHeight: '24px',
        color: '#282E37'
    },
    buttonsContainer: {
        '& p': {
            fontFamily: 'Source Sans Pro',
            fontSize: '16px',
            fontWeight: FontWeightLightBold,
            lineHeight: '20px',
            textAlign: 'center',
            color: ColorEastBay
        },
        '& span': {
            display: 'flex',
            gap: '8px'
        },

        height: '40px',
        padding: '8px 12px 8px 12px',
        gap: '8px',
        borderRadius: '2px 0px 0px 0px',
        border: `1px solid ${ColorEastBay}`,
        textTransform: 'none',
        '&:focus, &:active': {
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        }
    },
    listButtonsCss: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: ColorBaseWhite,
        height: '66px',
        gap: '16px',
        float: 'right'
    },
    BoxCss: {
        display: 'flex',
        padding: '24px',
        paddingRight: '0px',
        '@media (max-width: 1200px)': {
            padding: '12px 2px'
        }
    },
    container: {
        borderRadius: '8px',
        border: `1px solid ${AtlasGreyLight}`,
        backgroundColor: theme.palette.common.white
    },
    btnContaier: {
        height: '50px',
        display: 'flex',
        alignItems: 'center'
    },
    filterContainer: {
        height: 'calc( 100vh - 218px )',
        overflow: 'auto',
        transition: 'max-height 0.3s ease',
        '@media (max-width: 1200px)': {
            height: 'calc( 100vh - 270px )'
        }
    },
    showMoreBtn: {
        textTransform: 'none',
        color: `${ColorEastBay}`,
        fontWeight: FontWeightLightBold,
        lineHeight: '20px',
        border: `1px solid ${ColorEastBay}`,
        padding: '8px 12px 8px 12px',
        maxWidth: '169px',
        display: 'block',
        margin: 'auto',
        '&:focus': {
            outline: 'none',
            boxShadow: `0px 0px 0px 1px ${ColorBaseWhite}, 0px 0px 0px 3px ${ColorBaseMulberryBlue}`,
            borderRadius: '2px'
        }
    },
    customHeader: {
        backgroundColor: '#EBEEF2'
    },
    filterTitle: {},
    selectedColumnsCss: {
        '&.MuiListItem-root.Mui-disabled': {
            opacity: 1
        },
        cursor: 'default',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: FontWeightLightBold,
        lineHeight: '20px',
        color: ColorBaseRichBlack
    },
    noDataContainer: {
        placeContent: 'center',
        placeItems: 'center',
        textAlign: 'center',
        backgroundColor: ColorBaseWhite,
        height: '100%',
        minHeight: '400px'
    },
    noDataTitle: {
        fontFamily: 'Source Sans Pro',
        fontSize: '28px',
        fontWeight: FontWeightLightBold,
        lineHeight: '32px',
        textAlign: 'center',
        color: ColorBaseRichBlack
    },
    noDatabodyText: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: FontWeightRegular,
        lineHeight: '20px',
        textAlign: 'center',
        color: ColorBaseRichBlack,
        marginBottom: '24px'
    }
}))

interface ActionDashboardProps {
    ActionList?: any[]
    actions: any
    total_count: number
}
export const ActionDashboard: FC<ActionDashboardProps> = (props) => {
    const classes = useStyles()

    useMinutesManager()
    const { canAddMinutes } = useMinutesManager()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [selectedAction, setSelectedAction] = useState<Details | null>(null)
    const [formDataDisplay, setFormDataDisplay] = useState<any>({})
    const handleEdit = useCallback(
        (params: Details | any) => {
            if (params?.column?.colId === 'text') {
                setIsOpen(true)
                params.action_title = params.data.text
                params.assigned_to = params.data.assignees.map((assin) => assin.text).join(', ')
                params.minutesSectionId = params.data.minutes_section_id
                params.data.sections = [
                    {
                        id: params?.data.minutes_section_id?.toString?.(),
                        name: params.data.section_name,
                        sectionType: 'minutes'
                    }
                ]
                setSelectedAction(params.data)
            }
        },
        [setSelectedAction, setIsOpen]
    )
    const columns = useMemo(
        () =>
            canAddMinutes
                ? getActionColumnSchemaForAdmin(classes, handleEdit)
                : getActionColumnSchemaForDirector(classes, handleEdit),
        [classes, handleEdit, canAddMinutes]
    ) as ColDef[]

    const [isLoading, setIsLoading] = useState(false)
    const [columnsData, setColumnsData] = useState<any[]>(columns)
    const [anchorEl, setAnchorEl] = useState(null)
    const [showFilters, hideFilters] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1)

    const [filteredData, setApplyedFilters] = useState<any>({})
    const [selectedColumnCount, setSelectedColumnCount] = useState<number>(columns.length)
    const [showToast, setShowToast] = useState(false)
    const [isPersisted, setIsPersisted] = useState(false)
    const [committeeForWhichUserHasActionsData, setCommitteeForWhichUserHasActionsData] = useState<
        any[]
    >([])

    const toggleFilters = useCallback(() => {
        hideFilters(!showFilters)
    }, [showFilters])
    const handleOpenMenu = useCallback((event) => {
        setAnchorEl(event.currentTarget)
    }, [])
    const handleCloseMenu = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const gridOptions = useMemo(
        () => ({
            headerClass: classes.customHeader,
            icons: {
                ...CustomSortIconsObject
            },
            headerHeight: 45,
            enableColResize: true,
            onCellClicked: handleEdit,
            onCellKeyPress: handleEdit
        }),
        [classes.customHeader, handleEdit]
    )

    const fetchCommitteeData = useCallback(async () => {
        if (committeeForWhichUserHasActionsData.length === 0) {
            const data = await getCommitteeForWhichUserHasAction()
            setCommitteeForWhichUserHasActionsData(
                data?.data.map(({ id, type, attributes }) => ({ id, type, ...attributes })) || []
            )
        }
    }, [committeeForWhichUserHasActionsData])

    useEffect(() => {
        fetchCommitteeData()
    }, [fetchCommitteeData])

    useEffect(() => {
        setIsLoading(true)
        ;(async () => {
            const urlParams = new URLSearchParams()
            const actionsData = await getFilteredActions(urlParams)
            props.actions.loadActions(actionsData)
            setIsLoading(false)
        })()
    }, [props.actions])

    const handlePageChange = useCallback(
        async (data, count, sortModel?: boolean) => {
            setIsLoading(true)
            setPage(count)
            const urlParams = new URLSearchParams()
            for (const key in data) {
                if (data[key]) {
                    urlParams.append(key, data[key])
                }
            }
            urlParams.append(GRID_UTILS.PAGE, count)
            urlParams.append(GRID_UTILS.SIZE, GRID_UTILS.PAGE_SIZE)
            const actionsData = await getFilteredActions(urlParams)
            if (sortModel) props.actions.loadActions(actionsData)
            else props.actions.loadActionsMore(actionsData)
            setIsLoading(false)
        },
        [props.actions]
    )

    const filterColumns = useMemo(() => columnsData.filter((col) => !col.hide), [columnsData])

    const handleChange = useCallback(
        (event) => {
            const { name, checked } = event.target
            const updatedColumns = columnsData.map((col) => {
                if (col.field === name) {
                    return {
                        ...col,
                        hide: !checked
                    }
                }
                return col
            })
            setColumnsData(updatedColumns)

            const persistData = updatedColumns.reduce((acc, col) => {
                if (!col.hide) {
                    acc.push(col.field)
                }
                return acc
            }, [])
            setSessionStorageItem('persistedStateData', JSON.stringify(persistData))
        },
        [columnsData]
    )

    useEffect(() => {
        const storedColumns = getSessionStorageItem('persistedStateData') || JSON.stringify([])
        const parsedColumns = JSON.parse(storedColumns)
        if (parsedColumns?.length && !isPersisted) {
            setIsPersisted(true)
            setColumnsData((prev) => {
                const updatedColumns = prev.map((col) => ({
                    ...col,
                    hide: !parsedColumns.includes(col.field)
                }))
                return updatedColumns
            })
        }
    }, [isPersisted])

    const [isDownloading, setDownloading] = useState<boolean>(false)
    const handleDownloadFile = useCallback(({ response, responseType }) => {
        saveFile({ response, responseType })
        setDownloading(false)
    }, [])

    const makeHandleExportMinutes = useCallback(async () => {
        try {
            const urlParams = new URLSearchParams()
            for (const key in filteredData) {
                if (filteredData[key]) {
                    urlParams.append(key, filteredData[key])
                }
            }

            const selectedColumns = columnsData
                .reduce((acc, col) => (col.hide ? acc : [...acc, col.field]), [])
                .join(',')

            urlParams.append('columns', selectedColumns)

            const response = await exportXlsList(urlParams)
            handleDownloadFile({ response, responseType: 'xlsx' })
        } catch (error) {
            console.error(error)
            setDownloading(false)
        }
    }, [handleDownloadFile, filteredData, columnsData])

    const exportReportClickHandler = useCallback(() => {
        if (props.ActionList?.length === 0 || filterColumns.length === 0) {
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false)
            }, 6000)
            return
        }
        setDownloading(true)
        makeHandleExportMinutes()
    }, [props.ActionList, makeHandleExportMinutes, filterColumns.length])

    const data = useMemo(
        () =>
            props?.ActionList?.map((it) => ({
                ...it,
                minutesSectionId: it.minutes_section_id!,
                minutesId: it.minutes_document_id,
                action_title: it.text,
                due_date: it.due_date ?? i18n.t('NONE'),
                completed_date: it.completed_date,
                status: it.status ?? it.completionStatus,
                notificationStatus: it.notificationStatus,
                committeeId: it.committee_id,
                notes: it.note
            })),
        [props.ActionList]
    )
    const handleNotificationStatusChange = useCallback(() => {
        if (selectedAction?.id && data) {
            const selecteddata = data?.find((it) => it?.id === selectedAction?.id) ?? {}
            if (selecteddata) {
                setSelectedAction((pre) => ({
                    ...(pre ?? ({} as any)),
                    notificationStatus: selecteddata?.notificationStatus || ''
                }))
                props.actions.sendNotification({
                    ...selectedAction,
                    notification_status: selecteddata?.notificationStatus || ''
                })
            }
        }
    }, [data, selectedAction, props.actions])

    const RightDrawerMemo = useMemo(
        () => (
            <RightDrawer
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                selectedAction={selectedAction}
                handleNotificationStatusChange={handleNotificationStatusChange}
            />
        ),
        [isOpen, selectedAction, handleNotificationStatusChange]
    )
    const handleCellValueChanged = useCallback(
        (params: { data: any }) => {
            setIsLoading(true)
            const payload = {
                id: params.data?.id,
                action_completed: params.data?.action_completed,
                completion_status: params.data?.action_completed
            }
            props.actions.actionComplete(JSON.stringify(payload)).then((res) => {
                props.actions.actionCompleteFullfilled(res.value)
                setIsLoading(false)
            })
        },
        [props]
    )

    const HandleSortChanged = useCallback(
        (params) => {
            const sortModel = params.columnApi.getColumnState().find((s) => s.sort != null) ?? {}
            const sortobj = {
                ...filteredData,
                sort_by: sortModel?.colId ?? undefined,
                sort_order: sortModel?.sort ?? undefined
            }
            setApplyedFilters(sortobj)
            handlePageChange(sortobj, 1, true)
        },
        [handlePageChange, filteredData]
    )
    useEffect(() => {
        const selectedCount = columnsData.filter((col) => !col.hide).length
        setSelectedColumnCount(selectedCount)
    }, [columnsData])

    const showButton = data && props?.total_count > data?.length && filterColumns.length > 0

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Box style={{ width: '100%' }}>
                <MinuteTakerHeader title={i18n.t('TOOLTIP_MINUTES')} />

                <Paper style={{ padding: '24px' }}>
                    <Box className={classes.minutesHeaderTitle}>
                        <Box
                            aria-label={i18n.t('ACTION_DASHBOARD')}
                            className={classes.titleCss}
                            role="heading"
                            aria-level={2}>
                            {i18n.t('ACTION_DASHBOARD')}
                        </Box>
                        <Box>
                            {canAddMinutes ? (
                                <Box
                                    className={classes.minutesHeaderDate}
                                    aria-label={i18n.t('ACTION_DASHBOARD_SUBTITLE')}>
                                    {i18n.t('ACTION_DASHBOARD_SUBTITLE')}
                                </Box>
                            ) : (
                                <Box
                                    className={classes.minutesHeaderDate}
                                    aria-label={i18n.t('ACTION_DASHBOARD_SUBTITLE_DIRECTOR')}>
                                    {i18n.t('ACTION_DASHBOARD_SUBTITLE_DIRECTOR')}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Paper>
                <LinearIndeterminate loading={isLoading} />
                <Box className={classes.BoxCss}>
                    <Grid container spacing={2} xs={12}>
                        <Grid item xs={12} sm={showFilters ? 4 : 12} md={showFilters ? 3 : 12}>
                            {showFilters && (
                                <Paper elevation={2} className={classes.filterContainer}>
                                    <ActionGridFilter
                                        columns={columnsData}
                                        setPage={setPage}
                                        setIsLoading={setIsLoading}
                                        setApplyedFilters={setApplyedFilters}
                                        committeeForWhichUserHasActionsData={
                                            committeeForWhichUserHasActionsData
                                        }
                                        formDataDisplay={formDataDisplay}
                                        setFormDataDisplay={setFormDataDisplay}
                                    />
                                </Paper>
                            )}
                        </Grid>

                        <Grid item sm={showFilters ? 8 : 12} md={showFilters ? 9 : 12}>
                            <Paper
                                className={classes.headerFlex}
                                aria-haspopup="true"
                                elevation={2}>
                                <Box aria-haspopup="true" className={classes.headerTitleFlex}>
                                    <Button
                                        className={classes.buttonsContainer}
                                        disableFocusRipple
                                        disableTouchRipple
                                        disableRipple
                                        onClick={toggleFilters}>
                                        {showFilters ? <CloseEyeIcon /> : <OpenEyeIcon />}
                                        <Typography
                                            variant="body1"
                                            aria-label={
                                                showFilters
                                                    ? i18n.t('HIDE_FILTERS')
                                                    : i18n.t('SHOW_FILTERS')
                                            }>
                                            {showFilters
                                                ? i18n.t('HIDE_FILTERS')
                                                : i18n.t('SHOW_FILTERS')}
                                        </Typography>
                                    </Button>
                                </Box>
                                <Box className={classes.listButtonsCss} aria-haspopup="true">
                                    <Button
                                        className={classes.buttonsContainer}
                                        disableFocusRipple
                                        disableRipple
                                        disableTouchRipple
                                        aria-haspopup="true"
                                        onClick={handleOpenMenu}
                                        data-analytics="MM-ManageColumns"
                                        aria-label={i18n.t('MANAGE_COLUMNS')}>
                                        <ManageColumnIcon />
                                        <Typography variant="body1">
                                            {i18n.t('MANAGE_COLUMNS')} ({filterColumns.length}/
                                            {columnsData.length})
                                        </Typography>
                                    </Button>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleCloseMenu}
                                        TransitionComponent={Fade}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center'
                                        }}>
                                        <MenuItem disabled className={classes.selectedColumnsCss}>
                                            <Typography
                                                className={`${classes.selectedColumnsCss} dropdownfocus`}>
                                                {filterColumns.length === columnsData.length
                                                    ? `${i18n.t('SELECTED_COLUMNS')} (${i18n.t(
                                                          'ALL'
                                                      )})`
                                                    : `${i18n.t(
                                                          'SELECTED_COLUMNS'
                                                      )} (${selectedColumnCount})`}
                                            </Typography>
                                        </MenuItem>

                                        {columnsData?.map((col) => (
                                            <MenuItem
                                                className="dropDownFocus"
                                                key={col.field}
                                                tabIndex={0}
                                                onClick={() =>
                                                    handleChange({
                                                        target: {
                                                            name: col.field,
                                                            checked: col.hide
                                                        }
                                                    })
                                                }
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' || e.key === ' ') {
                                                        e.preventDefault()
                                                        handleChange({
                                                            target: {
                                                                name: col.field,
                                                                checked: col.hide
                                                            }
                                                        })
                                                    }
                                                }}>
                                                <Checkbox
                                                    disableFocusRipple
                                                    disableRipple
                                                    disableTouchRipple
                                                    color="primary"
                                                    checked={!col.hide}
                                                    name={col.field}
                                                    onChange={handleChange}
                                                />
                                                {col?.headerName}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                    <AddActionEditActionButton
                                        className={classes.buttonsContainer}
                                        disableFocusRipple
                                        disableRipple
                                        disableTouchRipple
                                        onClick={exportReportClickHandler}
                                        style={{
                                            backgroundColor: isDownloading
                                                ? '#e6e6e6'
                                                : 'transparent',
                                            color: isDownloading ? '#B3B3B3' : '#5D7599',
                                            border: isDownloading
                                                ? '1px solid #E6E6E6'
                                                : '1px solid #5D7599'
                                        }}
                                        aria-label={i18n.t('EXPORT_TO_EXCEL')}
                                        aria-busy={isDownloading ? 'true' : 'false'}
                                        aria-disabled={isDownloading ? 'true' : 'false'}
                                        aria-pressed={isDownloading ? 'true' : 'false'}
                                        aria-live="assertive"
                                        aria-describedby="export-to-excel"
                                        data-analytics="MM-ExportActionsReport"
                                        disabled={isDownloading}>
                                        <ExportToExcelIcon />
                                        <Typography variant="body1">
                                            {i18n.t('EXPORT_TO_EXCEL')}
                                        </Typography>
                                    </AddActionEditActionButton>
                                </Box>
                            </Paper>
                            <Paper elevation={2} className={classes.container}>
                                <Box id="action-dashboard-grid">
                                    {filterColumns.length === 0 ? (
                                        <Box className={classes.noDataContainer}>
                                            <NoActionIcon />
                                            <Typography
                                                variant="body1"
                                                className={classes.noDataTitle}>
                                                {i18n.t('NO_COLUMNS_SELECTED.title')}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                className={classes.noDatabodyText}>
                                                {i18n.t('NO_COLUMNS_SELECTED.suggestion')}
                                            </Typography>
                                            <AddActionEditActionButton
                                                aria-label={i18n.t(
                                                    'NO_COLUMNS_SELECTED.reset_columns'
                                                )}
                                                data-analytics="MM-ResetColumns"
                                                disableFocusRipple
                                                disableRipple
                                                onClick={() => {
                                                    setColumnsData(columns)
                                                }}
                                                className={classes.showMoreBtn}>
                                                {i18n.t('NO_COLUMNS_SELECTED.reset_columns')}
                                            </AddActionEditActionButton>
                                        </Box>
                                    ) : (
                                        <AgGridComponent
                                            gridId="action-dashboard-grid"
                                            columns={columnsData}
                                            data={data ?? []}
                                            gridOptions={gridOptions}
                                            OverlayNoRowsTemplate={OverlayNoRowsTemplate}
                                            onCellValueChanged={handleCellValueChanged}
                                            onSortChanged={HandleSortChanged}
                                            formData={filteredData}
                                            gridClass={showButton ? 'collapsed' : 'expanded'}
                                        />
                                    )}
                                </Box>
                                {showButton && (
                                    <Box className={classes.btnContaier}>
                                        <Button
                                            disableFocusRipple
                                            disableRipple
                                            onClick={() => handlePageChange(filteredData, page + 1)}
                                            data-testid="ShowMore_Btn"
                                            data-analytics="MM-ShowMoreActions"
                                            aria-label={i18n.t('SHOW_MORE_DATA_ACTIONS')}
                                            className={classes.showMoreBtn}>
                                            {i18n.t('SHOW_MORE_DATA_ACTIONS')}
                                        </Button>
                                    </Box>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
                {showToast && (
                    <Toast
                        message={
                            filterColumns.length === 0
                                ? i18n.t('NO_COLUMNS_SELECTED.select_columns_suggestion')
                                : i18n.t('TOAST_MESSAGE')
                        }
                        showToast={showToast}
                        setShowToast={setShowToast}
                    />
                )}
                {RightDrawerMemo}
            </Box>
        </MuiPickersUtilsProvider>
    )
}
const mapStateToProps = (state: any) => {
    return {
        ActionList: state.actionDashboardReducer.dataState.ActionList,
        total_count: state.actionDashboardReducer.dataState.total_count,
        dateInputMask: actionEditorSelectors.dateInputMask(state.actionEditorReducer)
    }
}
const mapDispatchToProps = (dispatch) => {
    const {
        loadActions,
        loadActionsMore,
        actionComplete,
        actionCompleteFullfilled,
        sendNotification
    } = actions

    return {
        actions: bindActionCreators(
            {
                loadActions,
                loadActionsMore,
                actionComplete,
                actionCompleteFullfilled,
                sendNotification
            },
            dispatch
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionDashboard)

const Toast = ({ message, showToast, setShowToast }) => {
    const classes = useStyles()
    return (
        showToast && (
            <Box className={classes.toast} role="alert" aria-live="assertive" aria-atomic="true">
                <WarningAlertIcon />
                <Box aria-label={i18n.t('TOAST_MESSAGE')} className={classes.toastMessage}>
                    {message}
                </Box>

                <AddActionEditActionButton
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    aria-pressed={showToast ? 'true' : 'false'}
                    onClick={() => setShowToast(false)}
                    aria-label={i18n.t('TOAST_DISMISS_BUTTON')}>
                    {i18n.t('TOAST_DISMISS_BUTTON')}
                </AddActionEditActionButton>
            </Box>
        )
    )
}
