import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, createMuiTheme, Drawer, Switch, ThemeProvider, Typography } from '@material-ui/core'
import { translate } from 'components/shared/internationalization'
import { AtlasWhite_100, ColorBaseMulberryBlue } from 'assets/styles/variables'
import { AtlasIcon } from '@diligentcorp/atlas-react'
import { IconButton } from 'components/shared/StyledComponents'
import {
    getNotificationSettings,
    postNotificationSettings,
    patchNotificationSettings
} from 'businesslayer/networkrequests/notificationsSettings'
import { transientStorageManager } from '../../../businesslayer/minutesSessionStore'
import i18n from 'common/util/internationalization'

const drawerWidth = 658

const theme = createMuiTheme({
    overrides: {
        MuiSwitch: {
            switchBase: {
                width: '37px',
                height: '28px',
                color: AtlasWhite_100
            },
            colorPrimary: {
                '&.Mui-checked': {
                    color: AtlasWhite_100
                }
            },
            track: {
                // Controls default (unchecked) color for the track
                opacity: 0.2,
                backgroundColor: '#455D82',
                '.Mui-checked.Mui-checked + &': {
                    // Controls checked color for the track
                    opacity: 1,
                    backgroundColor: '#455D82'
                }
            }
        }
    }
})

const useStyles = makeStyles((theme) => ({
    drawer: {
        maxWidth: drawerWidth,
        [theme.breakpoints.down('md')]: {
            maxWidth: '100vw'
        },
        '@media (max-width: 768px)': {
            width: '100vw'
        }
    },
    drawerPaper: {
        position: 'fixed',
        maxWidth: drawerWidth,
        [theme.breakpoints.down('md')]: {
            maxWidth: '100vw'
        },
        '@media (max-width: 768px)': {
            width: '100vw'
        }
    },
    headerTitle: {
        fontWeight: 600,
        fontSize: '22px',
        padding: '16px',
        lineHeight: '26px'
    },
    settingsDrawerNotificationTitle: {
        fontWeight: 600,
        fontSize: '16px'
    },
    settingsDrawerNotificationSubtitle: {
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '20px',
        maxWidth: '546px'
    },
    headerBox: {
        height: '64px',
        backgroundColor: '#fff',
        zIndex: theme.zIndex.drawer + 1,
        flexShrink: 0,
        display: 'flex'
    },
    btnContainer: {
        float: 'right',
        display: 'flex',
        alignItems: 'center',
        margin: 'auto',
        marginRight: '16px',
        textDecoration: 'none'
    },
    panal: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    body: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '24px'
    },
    switch: {
        height: '28px',
        marginLeft: '15px',
        marginRight: '10px',
        marginTop: '0px',
        width: '52px',
        borderRadius: '26px',
        padding: '0px',
        cursor: 'pointer'
    },
    closeButton: {
        '&:focus, &:active': {
            padding: '2px',
            margin: '2px',
            outline: `2px solid ${ColorBaseMulberryBlue}`
        }
    },
    switchBUtton: {
        height: '30px',
        width: '80px',
        '&:focus, &:active': {
            outline: `2px solid ${ColorBaseMulberryBlue}`
        }
    }
}))

interface SettingsDrawerProps {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    isOpen: boolean
}

const SettingsDrawer: React.FC<SettingsDrawerProps> = ({ setIsOpen, isOpen }) => {
    const classes = useStyles()
    const toggleDrawer = useCallback(() => {
        setIsOpen(!isOpen)
    }, [setIsOpen, isOpen])
    const [checked, setChecked] = useState(true)
    const [responseObjectForNotification, setResponseObjectForNotification] = useState<{
        data: any[]
    }>({ data: [] })

    const handleChange = async (event: any) => {
        const newChecked = event.target.checked
        setChecked(newChecked)
        try {
            if (responseObjectForNotification?.data?.length === 0) {
                const payload = {
                    data: {
                        attributes: {
                            platform: transientStorageManager.platform,
                            site_name: transientStorageManager.siteName,
                            action_notification: newChecked
                        }
                    }
                }

                const createNotificationSettings = async () =>
                    await postNotificationSettings(JSON.stringify(payload))
                createNotificationSettings()
            } else {
                const payload = {
                    data: {
                        attributes: {
                            action_notification: newChecked
                        }
                    }
                }

                const updateNotificationSettings = async () =>
                    await patchNotificationSettings(
                        JSON.stringify(payload),
                        responseObjectForNotification.data[0]?.id
                    )
                updateNotificationSettings()
            }
        } catch (error) {
            console.error('Error updating settings:', error)
        }
    }

    useEffect(() => {
        const getSettings = async () => {
            const minutesDetails = await getNotificationSettings()
            setResponseObjectForNotification(minutesDetails)
            if (minutesDetails.data[0]?.attributes?.action_notification === false) {
                setChecked(false)
            } else if (
                (minutesDetails.data && minutesDetails.data.length === 0) ||
                minutesDetails.data[0]?.attributes?.action_notification === true
            ) {
                setChecked(true)
            }
        }

        if (isOpen) {
            getSettings()
        }
    }, [isOpen])
    return (
        <Drawer
            className={classes.drawer}
            classes={{ paper: classes.drawerPaper }}
            anchor="right"
            open={isOpen}
            onClose={toggleDrawer}>
            <div className={classes.panal}>
                <Box className={classes.headerBox}>
                    <Typography className={classes.headerTitle}>
                        {translate('NOTIFICATION_SETTINGS')}
                    </Typography>
                    <Box className={classes.btnContainer}>
                        <IconButton
                            disableFocusRipple
                            disableRipple
                            onClick={() => setIsOpen(!isOpen)}>
                            <div
                                className={classes.closeButton}
                                role="button"
                                aria-label="Close"
                                tabIndex={0}
                                onClick={() => setIsOpen(!isOpen)}
                                onKeyUp={(event) => {
                                    if (event.key === 'Enter' || event.key === ' ') {
                                        setIsOpen(!isOpen)
                                    }
                                }}>
                                <AtlasIcon data-testid="AtlasModal_CloseIcon" name="close" />
                            </div>
                        </IconButton>
                    </Box>
                </Box>
                <Box className={classes.body}>
                    <ThemeProvider theme={theme}>
                        <div
                            role="switch"
                            className={classes.switchBUtton}
                            aria-checked={checked}
                            tabIndex={0}
                            onKeyPressCapture={(event) => {
                                if (event.key === 'Enter' || event.key === ' ') {
                                    handleChange({
                                        target: { checked: !checked }
                                    })
                                }
                            }}
                            onClick={() =>
                                handleChange({
                                    target: { checked: !checked }
                                })
                            }>
                            <Switch
                                className={classes.switch}
                                checked={checked}
                                aria-label={i18n.t('NOTIFICATION_SETTINGS')}
                                onChange={handleChange}
                                color="primary"
                                inputProps={{
                                    'aria-label': i18n.t('NOTIFICATION_SETTINGS')
                                }}
                            />
                        </div>
                    </ThemeProvider>
                    <Box>
                        <Typography className={classes.settingsDrawerNotificationTitle}>
                            {translate('ALLOW_EMAIL')}
                        </Typography>
                        <Typography className={classes.settingsDrawerNotificationSubtitle}>
                            {translate('SEND_EMAIL_NOTIFICATIONS')}
                        </Typography>
                    </Box>
                </Box>
            </div>
        </Drawer>
    )
}

export default SettingsDrawer
